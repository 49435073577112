<template>
  <v-container grid-list-xl fluid>
    <listarCliente v-bind:titulo="'Consultar Cuenta Corriente'" v-bind:busmodulo="{ busmodulo }"></listarCliente>
    <!-- <consulta v-bind:codcli="cliente.cli_codigo"></consulta> -->
    <consulta></consulta>
  </v-container>
</template>

<script>

import clienteService from "@/services/ClienteServices";
import consulta from "@/components/ctacte/consulta.vue";
import listarCliente from "@/components/cliente/ListarClientes.vue";
export default {
  name: "CtaCte",
  components: { consulta,listarCliente },
  busmodulo: "CtaCte", //usado para el bus del selector
  data() {
    return {
      cliente: {},
      isAdmin: false
    };
  },
  created() {
    this.cliente = clienteService.current();
    this.$bus.$on('SelectorClienteSetCliente_'+this.busmodulo, () => {
      this.cliente = clienteService.current();
    });
  }
};
</script>